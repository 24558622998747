<template>
    <v-card flat style="max-width: 800px">
        <v-card-text>
            <v-col cols="12">
                <br />
                <strong>Her kommer mer info om frivillig.</strong>
            </v-col>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    name: '',
    props: {
        value: {
            type: Object,
        },
    },
    data() {
        return {
            readonly: false,
            person: {},
        };
    },
    computed: {
        changed: function () {
            return false;
        },
    },
    watch: {},

    /**
     * created
     */
    created: function () {
        if (this.value) {
            this.person = this.value;
            this.update();
        }
    },
    methods: {
        ...mapActions('api', ['request', 'formatPerson']),
        ...mapActions(['snackbar']),

        /**
         * update
         */
        update: function () {},

        /**
         * save
         */
        save: async function () {},

        /**
         * cancel
         */
        cancel: function () {},
    },
};
</script>
<style lang="scss"></style>
